import React from 'react';

const ExampleGallery = ({ setImage, setShowCropper }) => {
  const chooseExample = (example) => {
    fetch(example)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result);
          setShowCropper(true);
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) => console.error("Error fetching example image:", error));
  };

  return (
    <div className="examples">
      {Array.from({ length: 16 }, (_, i) => (
        <div key={i}>
          <div>
            <img
              src={`examples/example.${String(i).padStart(2, '0')}`}
              alt={`Example ${i}`}
              onClick={() => chooseExample(`examples/example.${String(i).padStart(2, '0')}`)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ExampleGallery;
