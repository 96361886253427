import {useLoader} from "@react-three/fiber";
import {TextureLoader} from "three";
import {Plane} from "@react-three/drei";

export const Blackboard = () => {
  const texture = useLoader(TextureLoader, '/bb-3.png');
  const BLACKBOARD_SIZE = 1500;

  return (
    <mesh position={[0, 0, -1]}>
      <Plane args={[BLACKBOARD_SIZE, BLACKBOARD_SIZE]}>
        <meshBasicMaterial attach="material" map={texture} />
      </Plane>
    </mesh>
  );
}
export default Blackboard;