import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from 'react-router-dom';

const Cap = ({ geometry, lights, setLights, callback, overrideColor }) => {
  console.log("Cap is rendered..");
  const meshRef = useRef();
  const [renderComplete, setRenderComplete] = useState(false);

  const colorArray = geometry.attributes.color
      ? [
        geometry.attributes.color.array[0],
        geometry.attributes.color.array[1],
        geometry.attributes.color.array[2],
      ]
      : [255, 255, 255];

  const [color, setColor] = useState(colorArray);
  const [searchParams] = useSearchParams();
  let editcolors = searchParams.get('editcolors') === 'yes';
  // const { modelId } = useParams();

  useEffect(() => {
    if (meshRef.current) {
      meshRef.current.geometry.computeVertexNormals();
      meshRef.current.onAfterRender = () => {
        if (!renderComplete) {
          setRenderComplete(true);
          if (callback) callback();
        }
      };
    }
  }, [geometry, renderComplete, callback]);

  const handleMeshClick = async () => {
    if (!editcolors) {
      return;
    }
    var newColor = prompt("Enter a new comma separated RGB color, e.g.: 255,255,0", color);
    if (newColor) {
      newColor = newColor.split(',');
      newColor = [
        parseInt(newColor[0]),
        parseInt(newColor[1]),
        parseInt(newColor[2]),
      ];
      setColor(newColor);
      setLights(true); // Turn on the lights when a new color is chosen
      // const response = await fetch('/api/glb/' + modelId + '/update_colors/', {
      //   method: 'POST',
      //   body: JSON.stringify({ geometry: name, color: newColor }),
      // });
      // const responseData = await response.json();
      // if (responseData.success !== 'yes') {
      //   alert('Error while updating GLB colors');
      // }
    }
  };

  var htmlcolor = 'rgba(' + color.join(',') + ')';
  if (overrideColor) {
    htmlcolor = overrideColor;
  }

  return (
      <mesh
          ref={meshRef}
          castShadow
          receiveShadow
          geometry={geometry}
          onClick={handleMeshClick}
      >
        <meshPhysicalMaterial
            attach="material"
            color={lights ? htmlcolor : '#FFFFFF'}
            emissive={htmlcolor}
            emissiveIntensity={lights ? 2.0 : 0.0}
            clearcoat={1.0}
            clearcoatRoughness={0.1}
            metalness={0.2}
            roughness={0.5}
            transmission={0.5}
            reflectivity={0.5}
            ior={1.5}
        />
      </mesh>
  );
};

export default Cap;
