import React, {useState, useEffect} from 'react';
import {Flex} from '@chakra-ui/react';
import DefaultButton from './DefaultButton';
import {useParams, useSearchParams} from 'react-router-dom';

const Toolbar = ({lights, setLights, windowHeight, windowWidth, overrideColor, setOverrideColor}) => {
    const {modelId, color} = useParams();
    const [fullscreen, setFullscreen] = useState(false);
    const [checkingout, setCheckingout] = useState(false);
    const [price, setPrice] = useState();
    let [searchParams] = useSearchParams();
    let screencap = searchParams.get('screencap') == 'yes';
    let videocap = searchParams.get('videocap') == 'yes';
    let embed = searchParams.get('embed') == 'yes';

    let allowfullscreen = !([
	    'iPad Simulator',
	    'iPhone Simulator',
	    'iPod Simulator',
	    'iPad',
	    'iPhone',
	    'iPod'
	  ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document));

    const navigateToCheckout = () => {
        setCheckingout(true);
        document.getElementById('reactcanvas').getElementsByTagName('canvas')[0].toBlob(async function (screenshot) {
            const formData = new FormData();
            formData.append('image', screenshot);
            const response = await fetch('/api/checkout/' + modelId + '/', {
                method: 'POST',
                body: formData,
            });
            const responseData = await response.json();
            window.location.href = responseData.redirect;
        });
    };

    const doScreencap = () => {
        document.getElementById('reactcanvas').getElementsByTagName('canvas')[0].toBlob(async function (blob) {
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'canvas-image.png';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        });
    };

    const doVideocap = () => {
        const canvas = document.getElementById('reactcanvas').getElementsByTagName('canvas')[0];
        let recordedChunks = [];
        const stream = canvas.captureStream(30);
        var mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.ondataavailable = function (event) {
            if (event.data.size > 0) {
                recordedChunks.push(event.data);
            }
        };
        mediaRecorder.onstop = function () {
            const blob = new Blob(recordedChunks, {
                type: 'video/mov'
            });
            const url = URL.createObjectURL(blob);
            document.getElementById('downloadLink').href = url;
        };
        mediaRecorder.start();
        setTimeout(() => mediaRecorder.stop(), 10000);

    };

    const changeColor = () => {
        setLights(true);
        var COLORS = [
            '#ffaaaa',
            '#00ff00',
            '#44ffff',
            '#ffff00',
            // '#e699ff',
            // '#d48f02',
        ];
        if (overrideColor) {
            setOverrideColor(COLORS[(COLORS.indexOf(overrideColor) + 1) % COLORS.length]);
        } else {
            setOverrideColor(COLORS[0]);
        }
    };

    const toggleFullscreen = () => {
        if (fullscreen) {
            document.exitFullscreen();
            setFullscreen(false);
        } else {
            document.getElementById('thecanvasdiv').requestFullscreen();
            setFullscreen(true);
        }
    };

    useEffect(() => {
        fetch(`/api/glb/${modelId}/info/`).then((response) => response.json()).then((json) => {
            setPrice(json['price']);
        });    
    }, []);

    return (
        <div style={{position: 'fixed', top: (embed ? 20 : 0), left: 0, right: 0}}>
            <Flex
                as="nav"
                justify="space-between" // Adjust spacing to place items at start and end
                alignItems="center"
                p="2" // Add some padding around the buttons
                bg="transparent" // Set background, adjust as necessary
                width="full" // Ensure the toolbar spans the full width of the window
            >
                <Flex alignItems="center">
		    {!embed ? <DefaultButton onClick={() => window.location.href = '/'} variant='ghost'>Zurück</DefaultButton> : null}
                </Flex>
                {windowHeight < windowWidth ? <Flex
                    justifyContent="center"
                    alignItems="center"
                    gap="2" // Provide equal spacing between the buttons
                    // The following properties ensure this Flex container doesn't stretch and only takes up as much space as it needs, keeping the buttons centered
                    position="absolute"
                    left="50%"
                    transform="translateX(-50%)"
                >
                    {!embed && modelId != '9003630' && <DefaultButton onClick={changeColor}>Farbe wechseln</DefaultButton>}
                    <DefaultButton onClick={() => setLights(!lights)}>Licht an/aus</DefaultButton>
                    {(screencap || videocap) ? <div>
                        <DefaultButton onClick={doScreencap}>Screencap</DefaultButton>
                        <DefaultButton onClick={doVideocap}>Videocap</DefaultButton>
                        <a id="downloadLink" download="canvas-video.mp4">Download Video</a>
                    </div> : null}
                </Flex> : null}
                {(!embed && modelId != '9003630') && <Flex>
                    {price && <span style={{fontSize: '27px', fontWeight: 'bold'}}>{price}&nbsp;€&nbsp;&nbsp;</span>}
                    <DefaultButton style={{display: 'block', float: 'none'}} onClick={navigateToCheckout} variant='solid' isDisabled={checkingout}>{checkingout ? 'Lädt...' : 'in den Warenkorb'}</DefaultButton>
                </Flex>}
            </Flex>
            <div style={{position: 'fixed', bottom: '8px', left: 0, right: 0}}>
                {windowHeight >= windowWidth ? <div style={{textAlign: 'center', fontSize: '32px', fontWeight: 'bold', paddingRight: '8px'}}>
                    {!embed ? <DefaultButton onClick={changeColor}>Farbe wechseln</DefaultButton> : null}
                    &nbsp;
                    <DefaultButton onClick={() => setLights(!lights)}>Licht an/aus</DefaultButton>
                </div> : null}
            </div>
            {(embed && allowfullscreen) ? <div style={{position: 'fixed', bottom: '8px', right: '8px'}}>
                <DefaultButton onClick={toggleFullscreen}>{fullscreen ? 'X' : '⛶'}</DefaultButton>
            </div> : null}
        </div>
    );
};

export default Toolbar;
