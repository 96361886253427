import React, { useRef } from 'react';
import { Input } from '@chakra-ui/react';
import DefaultButton from './DefaultButton';

const ImageUpload = ({ setImage, setShowCropper }) => {
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setShowCropper(true);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <DefaultButton onClick={() => fileInputRef.current.click()}>Bild hochladen</DefaultButton>
      <Input type="file" accept="image/png, image/jpeg" onChange={handleFileChange} ref={fileInputRef} hidden />
    </div>
  );
};

export default ImageUpload;
