import React, { useEffect, useRef } from "react";

const Acryl = ({ geometry, callback }) => {
  console.log("Acryl is rendered...");
  const meshRef = useRef();

  useEffect(() => {
    if (meshRef.current) {
      meshRef.current.geometry.computeVertexNormals();
      const handleAfterRender = () => {
        if (callback) callback();
      };
      meshRef.current.onAfterRender = handleAfterRender;
    }
  }, [geometry, callback]);

  return (
      <mesh
          ref={meshRef}
          castShadow
          receiveShadow
          geometry={geometry}
          position={[0, 0, -0.01]}
      >
        <meshPhysicalMaterial
            attach="material"
            color="white"
            emissive="black"
            emissiveIntensity={0.0}
            metalness={0.0}
            roughness={0.5}
            clearcoat={0.0}
            transmission={1.0}
            ior={1.5}
        />
      </mesh>
  );
};

export default Acryl;
