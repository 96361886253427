import React, { useState, useCallback } from 'react';
import { Button } from '@chakra-ui/react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import DefaultButton from '../components/DefaultButton';
import getCroppedImg from '../utils/cropImage';
import fetchModel from '../utils/fetchModel';
import { usePreloadEnvironment } from './hooks/usePreloadEnvironment';

const CROP_INITIAL = {
  unit: '%',
  x: 0,
  y: 0,
  width: 100,
  height: 100
};

const CropperComponent = ({ image, crop, setCrop, navigate, setImage, setShowCropper }) => {
  const [isPreparing, setIsPreparing] = useState(false);
  const environmentLoaded = usePreloadEnvironment('/env7.hdr');
  const [rotation, setRotation] = useState(0);

  const handleBack = () => {
    setCrop(CROP_INITIAL);
    setImage(null);
    setShowCropper(false);
  };

  const handleCropConfirm = useCallback(async () => {
    try {
      let thecrop = crop;
      if (crop.width === 0 && crop.height === 0) {
        thecrop = { unit: "%", x: 0, y: 0, width: 100, height: 100 };
      }
      const croppedImage = await getCroppedImg(image, thecrop);
      setIsPreparing(true);

      navigate(`/model/loading/`);
      const modelId = await fetchModel(croppedImage, rotation);
      navigate(`/model/${modelId}/`);

    } catch (e) {
      console.error(e);
    } finally {
      setIsPreparing(false);
    }
  }, [crop, image, navigate, rotation]);

  return (
    <div>
      <div style={{ width: '100%', paddingLeft: '16px', paddingRight: '16px' }}>
        <br />
        <br />
        <Button
          colorScheme="teal"
          style={{ float: 'right' }}
          onClick={handleCropConfirm}
          disabled={isPreparing || !environmentLoaded}
        >
          {isPreparing ? 'Lädt...' : 'Auswahl bestätigen'}
        </Button>
        <DefaultButton colorScheme="teal" onClick={handleBack}>Zurück</DefaultButton>
        <br />
        <br />
        Wähle einen relevanten Bildausschnitt aus, falls notwendig:
        <br />
        <br />
        <div style={{ textAlign: 'center', padding: '8px', overflow: 'hidden'}}>
          <ReactCrop crop={crop} onChange={(crop, percentCrop) => setCrop(percentCrop)} style={{ maxHeight: 'calc(max(100vh - 150px, 100px))', transform: 'rotate(' + rotation + 'deg)' }}>
            <img src={image} alt="Crop" />
          </ReactCrop>
        </div>
        <br />
        Rotiere das Bild, falls notwendig {rotation}:
        <br />
        <br />
        <div style={{border: '1px solid white', paddingTop: '3px'}}>
          <input style={{width: '100%', background: 'd3d3d3'}} type="range" min="-180" max="180" value={rotation} onChange={(e) => setRotation(e.target.value)} step="1" />
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default CropperComponent;
