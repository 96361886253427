import React, { useEffect, useRef, useState } from 'react';
import { Canvas } from '../react-three-offscreen/Canvas.tsx';

const LoadingAnimation = ({ rendered }) => {
    const workerRef = useRef(null);
    const [workerReady, setWorkerReady] = useState(false);

    useEffect(() => {
        workerRef.current = new Worker(new URL('./blobworker.js', import.meta.url), { type: 'module' });
        console.log("Worker created!");

        workerRef.current.onmessage = (event) => {
            console.log("Worker message:", event.data);
        };

        // Mark the worker as ready
        setWorkerReady(true);

        return () => {
            if (workerRef.current) {
                console.log("Terminating worker", workerRef.current);
                workerRef.current.terminate();
                workerRef.current = null;
            }
        };
    }, []); // Empty dependency array ensures this effect runs only once when the component mounts

    useEffect(() => {
        if (rendered && workerRef.current) {
            console.log("Terminating worker due to render change", workerRef.current);
            workerRef.current.terminate();
            workerRef.current = null;
        }
    }, [rendered]);

    if (!workerReady) {
        return null; // or some loading spinner
    }

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 10,
                display: rendered ? 'none' : 'block',  // Hide canvas when rendered is true
            }}
        >
            <Canvas
                worker={workerRef.current}
                shadows
                camera={{ position: [0, 5, 10], fov: 50 }}
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'block',
                    backgroundColor: 'black'
                }}
            />
        </div>
    );
};

export default LoadingAnimation;
