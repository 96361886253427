import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Environment, PerspectiveCamera, Preload } from '@react-three/drei';
import { EffectComposer, Bloom, ToneMapping } from '@react-three/postprocessing';
import { ToneMappingMode } from 'postprocessing';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { Cap, BP, Acryl } from '../components/product_meshes';
import { Ruler, Blackboard } from '../components/info_meshes';
import * as THREE from 'three';
import {useSearchParams} from 'react-router-dom';

const DISTANCE_MAX = 28;
const DISTANCE_MIN = 10;

const Scene = ({ lights, setLights, windowWidth, windowHeight, modelId, onFirstRenderComplete, overrideColor }) => {
  console.log("Scene component is rendering...");

  const [geometries, setGeometries] = useState([]);
  const [bpGeometry, setBpGeometry] = useState(null);
  const [acrylGeometry, setAcrylGeometry] = useState(null);
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [rendered, setRendered] = useState(false);
  const renderCountRef = useRef(0);

  let [searchParams] = useSearchParams();
  let screencap = searchParams.get('screencap') == 'yes';
  let videocap = searchParams.get('videocap') == 'yes';
  let embed = searchParams.get('embed') == 'yes';

  const loadGLTFModel = useCallback(async (url) => {
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/draco/');
    loader.setDRACOLoader(dracoLoader);

    return new Promise((resolve, reject) => {
      loader.load(
          url,
          (gltf) => resolve(gltf),
          undefined,
          (error) => reject(error)
      );
    });
  }, []);

  useEffect(() => {
    const gltfPath = modelId === 'axunio_logo.glb' ? '/models/axunio_logo.glb' : `/api/glb/${modelId}/`;
    console.log("Loading model from:", gltfPath);

    loadGLTFModel(gltfPath).then((gltf) => {
      if (gltf && gltf.scene) {
        const loadedGeometries = [];
        gltf.scene.traverse((child) => {
          if (child.isMesh) {
            if (child.name === "geometry_0") {
              setBpGeometry(child.geometry);
            } else if (child.name === "geometry_1") {
              setAcrylGeometry(child.geometry);
            } else {
              loadedGeometries.push([child.name, child.geometry]);
            }
          }
        });
        setGeometries(loadedGeometries);

        const box = new THREE.Box3().setFromObject(gltf.scene);
        const size = new THREE.Vector3();
        box.getSize(size);
        setWidth(parseInt(size.x));
        setHeight(parseInt(size.y));
      }
    }).catch(error => {
      console.error('Error loading GLTF model:', error);
    });
  }, [modelId, loadGLTFModel]);

  const handleRenderComplete = () => {
    renderCountRef.current += 1;
    if (renderCountRef.current === geometries.length + (bpGeometry ? 1 : 0) + (acrylGeometry ? 1 : 0)) {
      console.log("All components have been rendered.");
      setRendered(true);
    }
  };

  useEffect(() => {
    if (rendered && typeof onFirstRenderComplete === 'function') {
      onFirstRenderComplete();
      console.log("onFirstRenderComplete()");
    }
  }, [rendered, onFirstRenderComplete]);

  const MAX_SIZE = 500;
  var scalefactor = MAX_SIZE / Math.max(width, height);

  const DIMENSIONS_DISTANCE = 32;

  let scale_horizontal = 1;
  if (windowWidth / windowHeight < (width / height) * 1.3) {
    scale_horizontal = (windowWidth / windowHeight) / ((width / height) * 1.3);
  }

  return (
      <Canvas
          id="reactcanvas" 
          shadows
          gl={{ preserveDrawingBuffer: true }}
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'black',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 0,
          }}
      >
        <PerspectiveCamera makeDefault position={[0, (screencap || embed) ? 10000 : 22, -26]} fov={DISTANCE_MAX} />
        <OrbitControls enablePan={false} maxDistance={DISTANCE_MAX} minDistance={DISTANCE_MIN} enableRotate={true} minPolarAngle={0} maxPolarAngle={Math.PI * 0.4} />
        <Environment files="/env7.hdr" resolution={1024} />

        <EffectComposer>
          <Bloom mipmapBlur luminanceThreshold={1} levels={8} intensity={0.5} />
          <ToneMapping mode={ToneMappingMode.ACESFilmic} />
        </EffectComposer>

        <group scale={0.02} position={[0, 0, 0]}>
          <group rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={scale_horizontal * 440 / (height * scalefactor)}>
            <Blackboard />
            {(width && height) && (
                <group>
                  <group position={[DIMENSIONS_DISTANCE + (height * scalefactor)/2, 0, 0]}>
                    <Ruler length={width * scalefactor} number={width} />
                  </group>
                  <group position={[0, -DIMENSIONS_DISTANCE - (width * scalefactor)/2, 0]} rotation={[0, 0, -Math.PI / 2]}>
                    <Ruler length={height * scalefactor} number={height} />
                  </group>
                </group>
            )}
            <group position={[(height * scalefactor)/2, -(width * scalefactor)/2, 0]} rotation={[0, 0, Math.PI / 2]} scale={scalefactor}>
              {bpGeometry && <BP geometry={bpGeometry} callback={handleRenderComplete} />}
              {acrylGeometry && <Acryl geometry={acrylGeometry} callback={handleRenderComplete} />}
              {geometries.map((geometry, index) => (
                  <Cap key={index} name={geometry[0]} geometry={geometry[1]} lights={lights} setLights={setLights} callback={handleRenderComplete} overrideColor={overrideColor} />
              ))}
            </group>
          </group>
        </group>
        <Preload all />
      </Canvas>
  );
};

export default Scene;
