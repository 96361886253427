const fetchModel = async (imageFile, rotation) => {
  console.log("fetchModel gets executed!")
  try {
    const formData = new FormData();
    formData.append('image', imageFile);
    formData.append('rotation', rotation);

    const response = await fetch('/api/upload/', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      alert('The model could not be generated. Please try again.');
      window.location.href = '/';
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data.model_id;
  } catch (error) {
    console.error('Error fetching model:', error);
    throw error;
  }
};

export default fetchModel;
