import { useEffect, useState } from 'react';
import { RGBELoader } from 'three-stdlib';

export const usePreloadEnvironment = (path) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const loader = new RGBELoader();
    loader.load(path, () => {
      setLoaded(true);
    });
  }, [path]);

  return loaded;
};
