import React, { useEffect, useRef } from "react";

const BP = ({ geometry, callback }) => {
  console.log("BP is rendered...");
  const meshRef = useRef();

  useEffect(() => {
    if (meshRef.current) {
      meshRef.current.geometry.computeVertexNormals();
      const handleAfterRender = () => {
        if (callback) callback();
      };
      meshRef.current.onAfterRender = handleAfterRender;
    }
  }, [geometry, callback]);

  return (
      <mesh
          ref={meshRef}
          castShadow
          receiveShadow
          geometry={geometry}
      >
        <meshPhysicalMaterial
            attach="material"
            color="#000000"
            roughness={1.0}
            metalness={0.1}
        />
      </mesh>
  );
};

export default BP;
