import {Text} from "@react-three/drei";

export const Ruler = ({ length , number }) => {
  const DIMENSIONS_TICKSIZE = 16;
  const DIMENSIONS_COLOR = '#aaa';
  const FONT_SIZE = 24;
  const FONT_DISTANCE = 24;

  return (
    <group>
      <mesh>
        <boxGeometry args={[1, length, 1]} />
        <meshBasicMaterial color={DIMENSIONS_COLOR} />
      </mesh>
      <mesh position={[0, length / 2, 0]}>
        <boxGeometry args={[DIMENSIONS_TICKSIZE, 1, 1]} />
        <meshBasicMaterial color={DIMENSIONS_COLOR} />
      </mesh>
      <mesh position={[0, -length / 2, 0]}>
        <boxGeometry args={[DIMENSIONS_TICKSIZE, 1, 1]} />
        <meshBasicMaterial color={DIMENSIONS_COLOR} />
      </mesh>
      <Text position={[FONT_DISTANCE, 0, 0]} rotation={[0, 0, Math.PI / 2]} fontSize={FONT_SIZE} color={DIMENSIONS_COLOR}>
        {Math.round(number / 10)} cm
      </Text>
    </group>
  );
};

export default Ruler;