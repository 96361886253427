import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ProductPage from './pages/ProductPage';
import TestPage from './pages/TestPage';
import { ChakraProvider } from '@chakra-ui/react';

import "./App.css";

function App() {
  return (
    <ChakraProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/model/:modelId/" element={<ProductPage/>} />
          </Routes>
        </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
